<template>
    <el-dialog :close-on-click-modal="false" title="超48小时未送医废医院名单" :visible.sync="show" width="900px" class="selfInputBox">


        <el-table class="el-tableII FanfuTableBox" :data="data.list" stripe height="500" border ref="multipleTable">



            <!-- 
 abbreviation: null
                auth: "1,223,3,4"
                cate_id: 30
                city_id: 38
                create_time: "2023-07-25 19:32:07"
                create_user: 260
                h_id: 17
                has_child: 0
                hazardous_end_time: null
                hazardous_start_time: null
                hazardous_type: 0
                id: 707
                is_hazardous: 1
                mark: 1
                name: "11111所属医院名称"
                pid: 0
                province_id: 37
                qybh: null
                qymc: null
                region_id: 39
                sort: 65535
                status: 1
                type: 1
                update_time: "2023-07-25 19:32:07"
                update_user: 0 -->


            <el-table-column show-overflow-tooltip type="selection" min-width="40" align="center" fixed="left" />
            <el-table-column show-overflow-tooltip label="序号" type="index" align="center" fixed="left" />

            <el-table-column show-overflow-tooltip prop="name" label="医院" align="left" min-width="150" />
            <!-- <el-table-column show-overflow-tooltip prop="qymc" label="企业名称" align="left" min-width="150" />
            <el-table-column show-overflow-tooltip prop="qybh" label="企业编号" align="left" min-width="150" /> -->





            <!-- <el-table-column show-overflow-tooltip prop="number" label="医废编号" align="left" min-width="150" /> -->
            <!-- <el-table-column show-overflow-tooltip prop="level_name" label="科室" align="left" min-width="150" />
            <el-table-column show-overflow-tooltip prop="item_name" label="类型" align="left" min-width="60" />
            <el-table-column show-overflow-tooltip prop="pinyin" label="总重量(Kg)" align="left" min-width="83" /> -->

            <!-- <el-table-column show-overflow-tooltip prop="" label="是否封箱" align="left" min-width="90">
                <template slot-scope="scope">
                    <el-button type="primary" round v-if="scope.row.rkstatus == 1">已封箱</el-button>
                    <el-button type="info" round v-if="scope.row.rkstatus == 2">未封箱</el-button>
                </template>
            </el-table-column> -->

            <!-- <el-table-column show-overflow-tooltip prop="realname" label="收集转移人" align="left" min-width="120" /> -->

            <!-- <el-table-column show-overflow-tooltip prop="" label="收集时间" align="left" min-width="150">
                <template slot-scope="scope">
                    <span>{{ scope.row.create_time }}</span>
                </template>
            </el-table-column> -->


            <!-- <el-table-column show-overflow-tooltip prop="Temporary" label="科室交接人员签字" align="center" min-width="120">
                <template slot-scope="scope">
                    <img src="" v-if="scope.row.sign_img" alt="" v-bind:src="GLOBAL.BASE_URL + scope.row.sign_img"
                        class="CoverImage" />
                </template>
            </el-table-column>

            <el-table-column show-overflow-tooltip prop="Third" label="转运人员收集签字" align="center" min-width="120">
                <template slot-scope="scope">
                    <img src="" v-if="scope.row.signa_img" alt="" v-bind:src="GLOBAL.BASE_URL + scope.row.signa_img"
                        class="CoverImage" />
                </template>
            </el-table-column> -->




            <!-- <el-table-column show-overflow-tooltip prop="Third" label="科室交接人员签字" align="center" width="" min-width="120">
                <template slot-scope="scope">
                    <img src="" v-if="scope.row.sign_img" alt="" v-bind:src="GLOBAL.BASE_URL + scope.row.sign_img"
                        class="CoverImage" />
                </template>
            </el-table-column>

            <el-table-column show-overflow-tooltip prop="Third" label="转运人员收集签字" align="center" width="" min-width="120">
                <template slot-scope="scope">
                    <img src="" v-if="scope.row.signa_img" alt="" v-bind:src="GLOBAL.BASE_URL + scope.row.signa_img"
                        class="CoverImage" />
                </template>
            </el-table-column>

            <el-table-column show-overflow-tooltip prop="Third" label="转运人员入库签字" align="center" width="" min-width="120">
                <template slot-scope="scope">
                    <img src="" v-if="scope.row.in_storage_sign1_img" alt=""
                        v-bind:src="GLOBAL.BASE_URL + scope.row.in_storage_sign1_img" class="CoverImage" />
                </template>
            </el-table-column>

            <el-table-column show-overflow-tooltip prop="Third" label="暂存处人员入库签字" align="center" width="" min-width="130">
                <template slot-scope="scope">
                    <img src="" v-if="scope.row.in_storage_sign2_img" alt=""
                        v-bind:src="GLOBAL.BASE_URL + scope.row.in_storage_sign2_img" class="CoverImage" />
                </template>
            </el-table-column>

            <el-table-column show-overflow-tooltip prop="Third" label="转运人员出库签字" align="center" width="" min-width="120">
                <template slot-scope="scope">
                    <img src="" v-if="scope.row.out_storage_sign1_img" alt=""
                        v-bind:src="GLOBAL.BASE_URL + scope.row.out_storage_sign1_img" class="CoverImage" />
                </template>
            </el-table-column>

            <el-table-column show-overflow-tooltip prop="Third" label="第三方转运人员签字" align="center" width="" min-width="130">
                <template slot-scope="scope">
                    <img src="" v-if="scope.row.out_storage_sign2_img" alt=""
                        v-bind:src="GLOBAL.BASE_URL + scope.row.out_storage_sign2_img" class="CoverImage" />
                </template>
            </el-table-column> -->





            <!-- <el-table-column show-overflow-tooltip label="操作" align="center" width="75">
                <template slot-scope="scope">
                    <div class="operate">
                        <el-button type="primary" @click="edit(scope.row)" v-if="scope.row.state" class="operationBtn">
                            恢复
                        </el-button>
                        <el-button type="danger" @click="del(scope.row)" class="operationBtn">
                            删除
                        </el-button>
                    </div>
                </template>
            </el-table-column> -->
        </el-table>


        <el-pagination layout="total, prev, pager, next" background :current-page.sync="data.page"
            :page-size="data.size" :total="data.total" @current-change="handleCurrentChange" />


    </el-dialog>
</template>

<script>
    export default {

        data() {
            return {
                show: false,



                data: {
                    list: [],
                    page: 1,
                    size: 15,
                    total: 0,
                },



            }
        },
        methods: {
            open() {

                // 查询超48小时未送医废

                this.$http.get('/api/number/notDataBy48Houser', {
                    page_size: this.data.size,
                    page: this.data.page
                }).then((res) => {
                    console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);

                    if (res.code == 200) {

                        this.data.list = res.data.data
                        this.data.total = res.data.total


                        this.show = true
                    }

                    if (res.code != 200) { //请求错误
                        this.$message.error(res.msg);
                    }
                }).catch((err) => {
                    console.log(err)
                });



            },

            handleCurrentChange(val) {
                console.log(`当前页: ${val}`)

                this.data.page = val


                this.open()


            },



        }
    }
</script>

<style>
    .SelfSelectI {
        width: 200px;
    }

    .selfInputBox .SelfSelectI .el-input__inner {
        width: 90% !important;
    }
</style>


<style scoped>
    /deep/.el-dialog__body {
        padding-bottom: 50px;

        /* border: 1px red solid;
        box-sizing: content-box; */
    }
</style>