<template>
    <div class="level_index level_indexII">

        <div class="statisticsBox">

            <div class="statisticsCell">
                <p class="title">数据统计</p>

                <statistics :label="'收集'" :num="todayCount.today.in_pak" :weight="todayCount.today.in_pak_weight"
                    :colorVal="'#3A8FEA'" :imgVal="'http://yifei-php-admin.tjjtln.com/daping/wjw/img/collect.png'">
                    <!-- :imgVal="'fa-dropbox'" -->
                </statistics>
                <statistics :label="'入库'" :num="todayCount.today.now_pak" :weight="todayCount.today.now_pak_weight"
                    :colorVal="'#0FACD5'" :imgVal="'http://yifei-php-admin.tjjtln.com/daping/wjw/img/in.png'">
                    <!-- :imgVal="'fa-inbox'" -->
                </statistics>
                <statistics :label="'出库'" :num="todayCount.today.out_pak" :weight="todayCount.today.out_pak_weight"
                    :colorVal="'#36CFC9'" :imgVal="'http://yifei-php-admin.tjjtln.com/daping/wjw/img/out.png'">
                    <!-- :imgVal="'fa-sign-out'" -->
                </statistics>

            </div>

            <div class="statisticsCell">
                <p class="title">出库预警</p>


                <statistics :typeVal="36" :num="todayCount.hours36.pak" :weight="todayCount.hours36.pak_weight"
                    :colorVal="'#F9954C'" :time="'36'" :imgVal="'http://yifei-php-admin.tjjtln.com/daping/wjw/img/Sthint.png'">
                    <!-- :imgVal="'fa-warning'" -->
                </statistics>

                <statistics :typeVal="48" :num="todayCount.hours48.pak" :weight="todayCount.hours48.pak_weight"
                    :colorVal="'#FA5A5C'" :time="'48'" :imgVal="'http://yifei-php-admin.tjjtln.com/daping/wjw/img/Stplic.png'">
                    <!-- :imgVal="'fa-window-close-o'" -->
                </statistics>

            </div>


        </div>


        <div class="search">

            <el-form :inline="true" :model="search">
                <!-- <el-form-item prop="title"> -->
                <!-- <el-input v-model="search.title" placeholder="请输入关键字" /> -->
                <!-- <el-select v-model="value1" clearable placeholder="请选择入库人" class="SelfSelect">
                        <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select> -->
                <!-- <el-select v-model="value2" clearable placeholder="请选择所属医院" class="SelfSelect">
                        <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select> -->
                <!-- <el-select v-model="value3" clearable placeholder="请选择类型" class="SelfSelect">
                        <el-option v-for="item in options3" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select> -->
                <!-- <el-select v-model="value4" clearable placeholder="请选择科室" class="SelfSelect">
                        <el-option v-for="item in options4" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select> -->
                <!-- <el-select v-model="value5" clearable placeholder="请选择入库时间" class="SelfSelect">
                        <el-option v-for="item in options5" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select> -->

                <!-- <el-select v-model="value5" clearable placeholder="请选择状态" class="SelfSelect">
                        <el-option v-for="item in options5" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select> -->



                <el-form-item label="医废编号">
                    <el-input clearable v-model="wasteInfo.number" placeholder="请输入医废编号" class="SelfSelect" />
                </el-form-item>

                <el-form-item label="医废类型" v-show="ISSenior">
                    <el-select clearable v-model="wasteInfo.item_id" placeholder="请选择医废类型" class="SelfSelect">
                        <el-option :label="item.name" :value="item.id" v-for="item in ItemTypeList"
                            :key="item.id"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="所属医院" v-show="ISSenior">
                    <el-select clearable v-model="wasteInfo.dep_id" placeholder="请选择所属医院" class="SelfSelect">
                        <el-option :label="item.name" :value="item.id" v-for="item in depList"
                            :key="item.id"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="是否封箱" v-show="ISSenior">
                    <!-- 是否封箱：1已封箱 2未封箱 -->
                    <el-switch v-model="wasteInfo.rkstatus" :active-value="1" active-color="#5BD995" active-text="是"
                        :inactive-value="2" inactive-color="#D9D9D9" inactive-text="否" class="switchSelfBox"
                        style="margin-top: 5px">
                    </el-switch>
                </el-form-item>

                <el-form-item label="科室" v-show="ISSenior">
                    <el-select v-model="wasteInfo.level_id" clearable placeholder="请选择科室" class="SelfSelect">
                        <el-option :label="item.name" :value="item.id" v-for="item in levelList"
                            :key="item.id"></el-option>
                    </el-select>
                </el-form-item>


                <el-form-item label="封箱时间" v-show="ISSenior">
                    <!-- <el-date-picker v-model="wasteInfo.sealing_time" type="date" format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd" placeholder="选择封箱时间" class="SelfSelect">
                        </el-date-picker> -->

                    <el-date-picker v-model="wasteInfo.sealing_time" type="daterange" range-separator="至"
                        start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                        style="height: 36px; display: flex;align-items: baseline;">
                    </el-date-picker>

                </el-form-item>


                <!-- </el-form-item> -->


                <el-form-item>
                    <el-button type="primary" @click="getList()">
                        <i class="fa fa-search" aria-hidden="true"></i>
                        查询
                    </el-button>
                    <!-- <el-button class="block" @click="toDel()">批量删除</el-button> -->
                </el-form-item>

                <el-form-item>
                    <div @click="warn">
                        <warn />
                    </div>
                </el-form-item>


                <!-- <el-form-item style="float: right;margin: 0;">
                    <el-button type="primary" @click="toSearch" style="">
                        高级搜索
                    </el-button>
                </el-form-item> -->


                <el-form-item style="float: right; margin: 0">
                    <el-button v-show="!ISSenior" type="primary" @click="ISSenior = !ISSenior" style="margin: 0">
                        高级搜索
                    </el-button>
                    <el-button v-show="ISSenior" type="danger" @click="ISSenior = !ISSenior" style="margin: 0">
                        高级搜索
                    </el-button>
                </el-form-item>

            </el-form>


        </div>


        <div class="OutputBox">

            <span><i class="fa fa-line-chart" aria-hidden="true"></i>
                <small>医废汇总：共{{data.total}}包，{{data.weight}}KG</small>
            </span>

            <el-button class="block" @click="DetailExport(wasteInfo)">明细导出</el-button>
            <el-button class="block" @click="SummaryExport(wasteInfo)">汇总导出</el-button>
        </div>


        <!-- <Fanfu-Table class="FanfuTableBox FanfuTableBoxI" v-model="select" :data="data" :search-height="50"
            :loading="loading" :get-list="getList" stripe border>

            <el-table-column show-overflow-tooltip prop="num" label="医废编号" align="left" />
            <el-table-column show-overflow-tooltip prop="hospital" label="医院" align="left" />
            <el-table-column show-overflow-tooltip prop="Department" label="科室" align="left" />
            <el-table-column show-overflow-tooltip prop="type" label="类型" align="left" width="" />

            <el-table-column show-overflow-tooltip prop="number" label="包数" align="left" width="" />

            <el-table-column show-overflow-tooltip prop="weight" label="收集重量(KG)" align="left" width="" />
            <el-table-column show-overflow-tooltip prop="state" label="收集状态" align="left" width="">
                <template slot-scope="scope">
                    <el-button type="primary" size="mini" v-if="scope.row.state">已出库</el-button>
                    <el-button type="danger" size="mini" v-else>未出库</el-button>
                </template>
            </el-table-column>


            <el-table-column show-overflow-tooltip prop="weight" label="封箱重量(Kg)" align="left" width="" />
            <el-table-column show-overflow-tooltip prop="state" label="封箱状态" align="left" width="">
                <template slot-scope="scope">
                    <el-button type="primary" size="mini" v-if="scope.row.state">已出库</el-button>
                    <el-button type="danger" size="mini" v-else>未出库</el-button>
                </template>
            </el-table-column>

            <el-table-column show-overflow-tooltip prop="weight" label="入库重量(Kg)" align="left" width="" />
            <el-table-column show-overflow-tooltip prop="state" label="入库状态" align="left" width="">
                <template slot-scope="scope">
                    <el-button type="primary" size="mini" v-if="scope.row.state">已出库</el-button>
                    <el-button type="danger" size="mini" v-else>未出库</el-button>
                </template>
            </el-table-column>

            <el-table-column show-overflow-tooltip prop="weight" label="出库重量(KG)" align="left" width="" />
            <el-table-column show-overflow-tooltip prop="state" label="出库状态" align="left" width="">
                <template slot-scope="scope">
                    <el-button type="primary" size="mini" v-if="scope.row.state">已出库</el-button>
                    <el-button type="danger" size="mini" v-else>未出库</el-button>
                </template>
            </el-table-column>



            <el-table-column show-overflow-tooltip prop="Third" label="科室交接人员签字" align="center" width="">
                <template slot-scope="scope">
                    <img src="" alt="" v-bind:src="scope.row.Third" class="CoverImage">
                </template>
            </el-table-column>

            <el-table-column show-overflow-tooltip prop="Third" label="转运人员收集签字" align="center" width="">
                <template slot-scope="scope">
                    <img src="" alt="" v-bind:src="scope.row.Third" class="CoverImage">
                </template>
            </el-table-column>

            <el-table-column show-overflow-tooltip prop="Third" label="转运人员入库签字" align="center" width="">
                <template slot-scope="scope">
                    <img src="" alt="" v-bind:src="scope.row.Third" class="CoverImage">
                </template>
            </el-table-column>

            <el-table-column show-overflow-tooltip prop="Third" label="暂存处人员入库签字" align="center" width="">
                <template slot-scope="scope">
                    <img src="" alt="" v-bind:src="scope.row.Third" class="CoverImage">
                </template>
            </el-table-column>

            <el-table-column show-overflow-tooltip prop="Third" label="转运人员出库签字" align="center" width="">
                <template slot-scope="scope">
                    <img src="" alt="" v-bind:src="scope.row.Third" class="CoverImage">
                </template>
            </el-table-column>

            <el-table-column show-overflow-tooltip prop="Third" label="第三方转运人员签字" align="center" width="">
                <template slot-scope="scope">
                    <img src="" alt="" v-bind:src="scope.row.Third" class="CoverImage">
                </template>
            </el-table-column>



            <el-table-column show-overflow-tooltip prop="time" label="封箱时间" align="left" />
            <el-table-column show-overflow-tooltip prop="time" label="入库时间" align="left" />
            <el-table-column show-overflow-tooltip prop="time" label="出库时间" align="left" />


            <el-table-column show-overflow-tooltip label="操作" align="center" width="67">
                <template slot-scope="scope">
                    <div class="operate">
                        <el-button type="primary" @click="edit(scope.row)" v-if="scope.row.state" class="operationBtn">
                            恢复
                        </el-button>
                        <el-button type="danger" @click="del(scope.row)" class="operationBtn">
                            删除
                        </el-button>
                    </div>
                </template>
            </el-table-column>


        </Fanfu-Table> -->


        <el-table class="el-tableII FanfuTableBox" :data="data.list" stripe height="100%" border ref="multipleTable"
            @row-click="rowclick">





            <el-table-column show-overflow-tooltip type="selection" min-width="40" align="center" fixed="left" />
            <el-table-column show-overflow-tooltip label="序号" type="index" align="center" fixed="left" />

            <el-table-column show-overflow-tooltip prop="number" label="医废编号" align="left" min-width="150" />
            <el-table-column show-overflow-tooltip prop="dep_name" label="医院名称" align="left" min-width="150" />

            <el-table-column show-overflow-tooltip prop="level_name" v-if="UserVersionId==1" label="科室名称" align="left"
                min-width="150" />
            <el-table-column show-overflow-tooltip prop="level_name" v-if="UserVersionId==2" label="科室" align="left"
                min-width="150" />



            <el-table-column show-overflow-tooltip prop="item_name" v-if="UserVersionId==1" label="医废名称" align="left"
                min-width="80" />
            <el-table-column show-overflow-tooltip prop="item_name" v-if="UserVersionId==2" label="类型" align="left"
                min-width="60" />


            <el-table-column show-overflow-tooltip prop="" v-if="UserVersionId==1" label="包数" align="left"
                min-width="80">
                <template slot-scope="scope">
                    1
                </template>
            </el-table-column>

            <!-- <el-table-column show-overflow-tooltip prop="pinyin" label="总重量(Kg)" align="left" min-width="83" /> -->

            <el-table-column show-overflow-tooltip prop="pinyin" v-if="UserVersionId==1" label="封箱重量(KG)" align="left"
                min-width="100" />

            <el-table-column show-overflow-tooltip prop="" v-if="UserVersionId==1" label="是否封箱" align="left"
                min-width="90">
                <template slot-scope="scope">
                    <!-- rkstatus 1已封箱 2未封箱 -->
                    <el-button type="primary" round v-if="scope.row.rkstatus == 1">已封箱</el-button>
                    <el-button type="info" round v-if="scope.row.rkstatus == 2">未封箱</el-button>
                </template>
            </el-table-column>


            <el-table-column show-overflow-tooltip prop="pinyin" v-if="UserVersionId==2" label="录入重量(Kg)" align="left"
                min-width="100" />


            <el-table-column show-overflow-tooltip prop="pinyin" v-if="UserVersionId==2" label="转运重量(KG)" align="left"
                min-width="100" />

            <el-table-column show-overflow-tooltip prop="" v-if="UserVersionId==2" label="转运状态" align="left"
                min-width="90">
                <template slot-scope="scope">
                    <!-- create_user 0或空 未转运 -->
                    <el-button type="primary" round v-if="scope.row.create_user">已转运</el-button>
                    <el-button type="info" round v-else>未转运</el-button>
                </template>
            </el-table-column>





            <el-table-column show-overflow-tooltip prop="" label="入库状态" align="left" min-width="90">
                <template slot-scope="scope">
                    <!-- is_in_storage 	是否入库0未入库1已入库2已出库 -->
                    <el-button type="danger" round v-if="scope.row.is_in_storage == 0">未入库</el-button>
                    <el-button type="primary" round v-if="scope.row.is_in_storage != 0">已入库</el-button>
                </template>
            </el-table-column>


            <el-table-column show-overflow-tooltip prop="pinyin" label="入库重量(KG)" align="left" min-width="100">
                <template slot-scope="scope">
                    <span type="primary" round v-if="scope.row.is_in_storage != 0">{{scope.row.pinyin}}</span>
                    <span type="danger" round v-else></span>
                </template>
            </el-table-column>





            <el-table-column show-overflow-tooltip prop="pinyin" label="出库重量(KG)" align="left" min-width="100" />

            <el-table-column show-overflow-tooltip prop="" label="出库状态" align="left" min-width="90">
                <template slot-scope="scope">
                    <!-- is_in_storage     是否入库0未入库1已入库2已出库 -->
                    <el-button type="primary" round v-if="scope.row.is_in_storage == 2">已出库</el-button>
                    <el-button type="info" round v-else>未出库</el-button>
                </template>
            </el-table-column>






            <el-table-column show-overflow-tooltip prop="" v-if="UserVersionId == 1" label="科室交接人员签字" align="center"
                min-width="120">
                <template slot-scope="scope">
                    <img src="" v-if="scope.row.sign_img" alt="" v-bind:src="GLOBAL.BASE_URL + scope.row.sign_img"
                        class="CoverImage" />
                </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="" v-if="UserVersionId == 1" label="转运人员收集签字" align="center"
                min-width="120">
                <template slot-scope="scope">
                    <img src="" v-if="scope.row.signa_img" alt="" v-bind:src="GLOBAL.BASE_URL + scope.row.signa_img"
                        class="CoverImage" />
                </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="" v-if="UserVersionId == 1" label="转运人员入库签字" align="center"
                min-width="120">
                <template slot-scope="scope">
                    <img src="" v-if="scope.row.in_storage_sign1_img" alt=""
                        v-bind:src="GLOBAL.BASE_URL + scope.row.in_storage_sign1_img" class="CoverImage" />
                </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="" v-if="UserVersionId == 1" label="暂存处人员入库签字" align="center"
                min-width="130">
                <template slot-scope="scope">
                    <img src="" v-if="scope.row.in_storage_sign2_img" alt=""
                        v-bind:src="GLOBAL.BASE_URL + scope.row.in_storage_sign2_img" class="CoverImage" />
                </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="" v-if="UserVersionId == 1" label="转运人员出库签字" align="center"
                min-width="120">
                <template slot-scope="scope">
                    <img src="" v-if="scope.row.out_storage_sign1_img" alt=""
                        v-bind:src="GLOBAL.BASE_URL + scope.row.out_storage_sign1_img" class="CoverImage" />
                </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="" v-if="UserVersionId == 1" label="第三方转运人员签字" align="center"
                min-width="130">
                <template slot-scope="scope">
                    <img src="" v-if="scope.row.out_storage_sign2_img" alt=""
                        v-bind:src="GLOBAL.BASE_URL + scope.row.out_storage_sign2_img" class="CoverImage" />
                </template>
            </el-table-column>







            <el-table-column show-overflow-tooltip prop="" v-if="UserVersionId == 2" label="录入人员签字" align="center"
                min-width="120">
                <template slot-scope="scope">
                    <img src="" v-if="scope.row.sign_img" alt="" v-bind:src="GLOBAL.BASE_URL + scope.row.sign_img"
                        class="CoverImage" />
                </template>
            </el-table-column>

            <el-table-column show-overflow-tooltip prop="" v-if="UserVersionId == 2" label="转运人员签字" align="center"
                min-width="120">
                <template slot-scope="scope">
                    <img src="" v-if="scope.row.signa_img" alt="" v-bind:src="GLOBAL.BASE_URL + scope.row.signa_img"
                        class="CoverImage" />
                </template>
            </el-table-column>

            <el-table-column show-overflow-tooltip prop="" v-if="UserVersionId == 2" label="入库人员签字" align="center"
                min-width="120">
                <template slot-scope="scope">
                    <img src="" v-if="scope.row.in_storage_sign1_img" alt=""
                        v-bind:src="GLOBAL.BASE_URL + scope.row.in_storage_sign1_img" class="CoverImage" />
                </template>
            </el-table-column>

            <el-table-column show-overflow-tooltip prop="" v-if="UserVersionId == 2" label="出库人员签字" align="center"
                min-width="120">
                <template slot-scope="scope">
                    <img src="" v-if="scope.row.out_storage_sign1_img" alt=""
                        v-bind:src="GLOBAL.BASE_URL + scope.row.out_storage_sign1_img" class="CoverImage" />
                </template>
            </el-table-column>





            <el-table-column show-overflow-tooltip prop="" v-if="UserVersionId==1" label="封箱时间" align="left"
                min-width="150">
                <template slot-scope="scope">
                    <span v-if="scope.row.sealing_time!=0">{{ timestampToTime(scope.row.sealing_time,2) }}</span>
                </template>
            </el-table-column>


            <el-table-column show-overflow-tooltip prop="" v-if="UserVersionId==2" label="录入时间" align="left"
                min-width="150">
                <template slot-scope="scope">
                    <span v-if="scope.row.collector_time!=0">{{ timestampToTime(scope.row.collector_time,2) }}</span>
                </template>
            </el-table-column>

            <el-table-column show-overflow-tooltip prop="" v-if="UserVersionId==2" label="转运时间" align="left"
                min-width="150">
                <template slot-scope="scope">
                    <span v-if="scope.row.create_time!=0">{{ timestampToTime(scope.row.create_time,2) }}</span>
                </template>
            </el-table-column>




            <el-table-column show-overflow-tooltip prop="" label="入库时间" align="left" min-width="150">
                <template slot-scope="scope">
                    <span>{{ timestampToTime(scope.row.storage_time,2)}}</span>
                </template>
            </el-table-column>

            <el-table-column show-overflow-tooltip prop="" label="出库时间" align="left" min-width="150">
                <template slot-scope="scope">
                    <span>{{timestampToTime(scope.row.out_storage_time,2)}}</span>
                </template>
            </el-table-column>



            <el-table-column show-overflow-tooltip label="操作" align="center" width="75">
                <!-- :min-width=" autoTableWidth(['sys:number:edit','sys:number:drop'],75)" -->
                <template slot-scope="scope">
                    <div class="operate">

                        <!-- <el-button type="primary" @click="edit(scope.row)" class="operationBtn"
                            v-if="getPurviewNew('sys:number:edit')"> 编辑 </el-button>
                    
                        <el-button type="danger" @click="del(scope.row)" class="operationBtn"
                            v-if="getPurviewNew('sys:number:drop')"> 删除 </el-button> -->

                        <el-button type="primary" @click="edit(scope.row)" v-if="scope.row.state" class="operationBtn">
                            恢复
                        </el-button>
                        <el-button type="danger" @click="del(scope.row)" class="operationBtn">
                            删除
                        </el-button>

                    </div>
                </template>
            </el-table-column>



        </el-table>

        <el-pagination layout="total, prev, pager, next, jumper" background :current-page.sync="data.page"
            :page-size="data.size" :total="data.total" @current-change="handleCurrentChange" />

        <br />
        <br />

        <Search ref="searchRef" v-model="search" @refresh="getList()" />

        <WasteList ref="WasteList" />

    </div>
</template>

<script>
    import Search from './components/WasteOutSearch'

    import statistics from './components/statistics.vue'
    import warn from './components/warn.vue'

    import WasteList from './components/WasteList'

    import resizeDetector from 'element-resize-detector'

    import {
        mapState,
        mapMutations
    } from 'vuex'

    export default {
        components: {
            Search,
            statistics,
            warn,
            WasteList
        },
        data() {
            return {
                search: {
                    title: '',
                    name2: '',
                    name3: '',
                    name4: '',
                    name5: '',
                    name6: ''
                },
                loading: false,
                select: [],
                data: {
                    list: [],
                    page: 1,
                    size: 15,
                    total: 0,
                    weight: 0,
                },

                ISSenior: false,

                wasteInfo: {
                    number: '', //医废编号
                    item_id: '', //医废类型标识id
                    collector_id: '', //小程序端录入人标识id
                    collector_time: '', //小程序端录入时间
                    create_user: '', //小程序端转运人标识id、 PDA端收集人 / 准运人标识id
                    create_time: '', //小程序端转运时间、 PDA端收集人录入 / 准运时间
                    dep_id: '', //组织机构标识id
                    rkstatus: '', //是否封箱： 1 已封箱 2 未封箱
                    sealing_time: '', //PDA封箱时间
                    level_id: '', //科室标识ID
                    is_in_storage: '', //是否入库0未入库1已入库2已出库入库时间
                    storage_time: '', //入库时间
                    out_storage_time: '' //出库时间
                },

                ItemTypeList: [], //医废类型

                depList: [], //机构

                levelList: [], //机构

                todayCount: {}, //今日统计

                ListType: 1, //1普通  36 48 

            }
        },
        mounted() {
            this.modifyLayout();
        },

        beforeMount() {
            this.modifyLayout();
        },
        beforeUpdate() {
            this.modifyLayout();
        },
        updated() {
            this.modifyLayout();
        },
        created() {

            this.ListType = 1 //1普通  36 48 

            this.todayCountFun()

            this.getList()

            this.getitemType()
            setTimeout(() => {
                this.ItemTypeList = this.ItemType
            }, 1000)

            this.getdep()
            setTimeout(() => {
                this.depList = this.DepList
            }, 1000)

            this.getlevel()
            setTimeout(() => {
                this.levelList = this.LevelList
            }, 1000)
        },

        computed: {
            ...mapState('m_item', [
                'LevelList',
                'PositionList',
                'DepList',
                'HealthList',
                'Province',
                'City',
                'District',
                'RoleList',
                'Depcate',
                'ItemType'
            ])
        },

        watch: {
            ISSenior(Value) {
                this.wasteInfo = {
                    number: '', //医废编号
                    item_id: '', //医废类型标识id
                    collector_id: '', //小程序端录入人标识id
                    collector_time: '', //小程序端录入时间
                    create_user: '', //小程序端转运人标识id、 PDA端收集人 / 准运人标识id
                    create_time: '', //小程序端转运时间、 PDA端收集人录入 / 准运时间
                    dep_id: '', //组织机构标识id
                    rkstatus: '', //是否封箱： 1 已封箱 2 未封箱
                    sealing_time: '', //PDA封箱时间
                    level_id: '', //科室标识ID
                    is_in_storage: '', //是否入库0未入库1已入库2已出库入库时间
                    storage_time: '', //入库时间
                    out_storage_time: '' //出库时间
                }
            },

            "wasteInfo": {
                deep: true, //深度监听设置为 true
                handler: function(newVal, oldVal) {
                    this.tosearch()
                }
            }

        },

        methods: {

            warn() {
                this.$refs.WasteList.open()
            },


            // 超36小时未处理
            dataBy36Houser() {
                this.$http.get('/api/number/dataBy36Houser', {
                    page_size: this.data.size,
                    page: this.data.page
                }).then((res) => {
                    console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);

                    if (res.code == 200) {

                        this.data.list = res.data.data
                        this.data.total = res.data.total

                    }

                    if (res.code != 200) { //请求错误
                        this.$message.error(res.msg);
                    }
                }).catch((err) => {
                    console.log(err)
                });
            },

            // 超48小时未处理
            dataBy48Houser() {
                this.$http.get('/api/number/dataBy48Houser', {
                    page_size: this.data.size,
                    page: this.data.page
                }).then((res) => {
                    console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);

                    if (res.code == 200) {

                        this.data.list = res.data.data
                        this.data.total = res.data.total

                    }

                    if (res.code != 200) { //请求错误
                        this.$message.error(res.msg);
                    }
                }).catch((err) => {
                    console.log(err)
                });
            },


            timeFun(time) {

                console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", time);

                this.ListType = time //1普通  36 48 

                if (time == 36) {
                    this.dataBy36Houser()
                } else {
                    this.dataBy48Houser()
                }

            },

            todayCountFun() {

                this.$http.get('/api/number/todayCount').then((res) => {
                    console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);

                    if (res.code == 200) {
                        this.todayCount = res.data
                    }

                    if (res.code != 200) { //请求错误
                        this.$message.error(res.msg);
                    }
                }).catch((err) => {
                    console.log(err)
                });

            },


            getList() {

                if (this.ISSenior == false) {
                    this.wasteInfo.rkstatus = ''
                }


                // var a = this.wasteInfo
                var a = {
                    ...this.wasteInfo
                }

                if (this.wasteInfo.sealing_time && this.wasteInfo.sealing_time.length > 1) {
                    a.sealing_time = this.wasteInfo.sealing_time.toString()
                }


                a.page_size = this.data.size
                a.page = this.data.page


                this.$http.get('/api/number/', a).then((res) => {
                    // console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);

                    if (res.code == 200) {
                        // this.setMedicalWasteItem(res.data.results)
                        // console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", this.MedicalWasteItem);

                        this.data.list = res.data.data
                        this.data.total = res.data.total

                        this.data.weight = res.data.weight

                        setTimeout(() => {
                            this.modifyLayout()
                        }, 1100)
                    }

                    if (res.code != 200) { //请求错误
                        this.$message.error(res.msg);
                    }
                }).catch((err) => {
                    console.log(err)
                });

            },

            tosearch() {
                this.data.page = 1
                this.getList()
            },

            handleCurrentChange(val) {
                console.log(`当前页: ${val}`)

                this.data.page = val

                // this.ListType = time //1普通  36 48 

                if (this.ListType == 1) {
                    this.getList()
                }

                if (this.ListType == 36) {
                    this.dataBy36Houser()
                }

                if (this.ListType == 48) {
                    this.dataBy48Houser()
                }

            },



            view(row) {
                console.log(row)
            },
            edit(row) {
                console.log(row)
            },
            del(row) {
                console.log(row)

                const h = this.$createElement;
                this.$msgbox({
                    title: '删除',
                    message: h('p', null, [
                        h('span', null, '该操作将删除所选数据，您确定要删除吗？删除后将无法恢复！'),

                    ]),
                    showCancelButton: true,
                    confirmButtonText: '删除',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            instance.confirmButtonLoading = true;
                            instance.confirmButtonText = '执行中...';
                            // setTimeout(() => {
                            //     done();
                            //     setTimeout(() => {
                            //         instance.confirmButtonLoading = false;
                            //     }, 300);
                            // }, 3000);


                            this.$http.del('/api/number/' + row.id, {}).then((res) => {
                                console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);


                                if (res.code == 200) {
                                    this.getList()
                                }

                                if (res.code != 200) { //请求错误
                                    this.$message.error(res.msg);
                                }

                                // setTimeout(() => {
                                done();
                                setTimeout(() => {
                                    instance.confirmButtonLoading = false;
                                }, 300);
                                // }, 3000);


                            }).catch((err) => {
                                console.log(err)
                            });








                        } else {
                            done();

                        }
                    }
                }).then(action => {
                    this.$message({
                        type: 'info',
                        message: 'action: ' + action
                    });
                });
            },


            toDel() {
                var row = 1
                console.log(row)

                const h = this.$createElement
                this.$msgbox({
                    title: '提示',
                    message: h('p', null, [
                        h('span', null, '您确定要删除所选信息吗？删除后可能会导致数据丢失且删除操作不能撤销！')
                    ]),
                    showCancelButton: true,
                    confirmButtonText: '删除',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            instance.confirmButtonLoading = true
                            instance.confirmButtonText = '执行中...'
                            setTimeout(() => {
                                done()
                                setTimeout(() => {
                                    instance.confirmButtonLoading = false
                                }, 300)
                            }, 3000)
                        } else {
                            done()
                        }
                    }
                }).then((action) => {
                    this.$message({
                        type: 'info',
                        message: 'action: ' + action
                    })
                })
            },


            toSearch() {
                this.$refs.searchRef.open()
            },


            modifyLayout() {
                const erd = resizeDetector();

                erd.listenTo(document.getElementsByClassName("search"), element => {
                    const width = element.offsetWidth;
                    const height = element.offsetHeight;

                    var a = 141 + 60 + 0 + height + 140;
                    if ($(".level_indexII .FanfuTableBox")[0]) {

                        $(".level_indexII .FanfuTableBox")[0].setAttribute("style", 'height: calc(100vh - ' +
                            a +
                            'px) !important');
                    }

                    var b = 141 + 60 + 70 + height + 140;
                    if ($(".level_indexII .el-table")[0]) {

                        $(".level_indexII .el-table")[0].setAttribute("style", 'height: calc(100vh - ' + b +
                            'px) !important');

                    }

                });

            },



            rowclick(row, column, event) {
                // console.log("!!!!!!!!!!!!!!!", row);
                // this.$refs.searchRef.open()
            },




            // getList(size) {
            //     if (size) {
            //         this.data.page = 1
            //         this.data.size = size
            //     }
            //     this.loading = true
            //     this.select = []
            //     this.$nextTick(() => {
            //         const start = this.data.size * (this.data.page - 1)
            //         const end = start + this.data.size
            //         this.data.total = list.length
            //         const data = []
            //         for (let i = start; i < end; i++) {
            //             if (list[i]) {
            //                 data.push(list[i])
            //             }

            //         }
            //         this.data.list = data
            //         setTimeout(() => {
            //             this.loading = false
            //         }, 1000)

            //         setTimeout(() => {
            //             this.modifyLayout();
            //         }, 1100)

            //     })
            // },



        },


    }
</script>

<style lang="scss" scoped>
    .statisticsBox {
        // border: 1px red solid;
        // box-sizing: content-box;
        // height: 150px;
        margin-bottom: 10px;
        display: flex;

        position: relative;

        .statisticsCell {

            border: 1px solid #ECECEC;
            box-sizing: content-box;
            // height: 150px;
            flex: 2;
            margin-left: 10px;

            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: stretch;
            flex-wrap: nowrap;


            .title {
                // border: 1px red solid;
                border-left: 2px #6A83FC solid;
                box-sizing: content-box;

                // width: 58px;
                // height: 15px;
                // line-height: 20px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #2F2F2F;
                margin: 5px 0px;
                padding: 0px 10px;

                position: absolute;

            }


        }

        .statisticsCell:nth-of-type(1) {
            flex: 3;
            margin-left: 0px;
        }

    }

    .SelfSelect {
        width: 150px;
        margin-right: 10px;

    }

    .OutputBox {
        // border: 1px red solid;
        // box-sizing: content-box;

        margin: -5px 0 10px 0;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #6A83FC;
        line-height: 20px;
        /* height: 30px; */
    }

    .OutputBox small {
        margin: 0 10px;
    }
</style>